<template>
  <div id="evento">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div v-if="evento.length != 0">
              <h3 class="card-title text-primary font-weight-bold">
                {{ evento.evento }}
              </h3>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/dashboard/' + evento.id_evento"
                  >
                    Inicio
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/localidades/' + evento.id_evento"
                  >
                    Localidades
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/compras/' + evento.id_evento"
                  >
                    Compras
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/entradas/' + evento.id_evento"
                  >
                    Entradas
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/ingresos/' + evento.id_evento"
                  >
                    Ingresos
                  </router-link>
                </li>
                <li v-if="perfil == 1" class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/usuarios_evento/' + evento.id_evento"
                  >
                    Usuarios
                  </router-link>
                </li>
                <li v-if="perfil == 1" class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    v-bind:to="'/imagen/' + evento.id_evento"
                  >
                    Imagen
                  </router-link>
                </li>
              </ul>

              <router-view
                :evento="evento"
                :perfil="perfil"
                @get_evento="get_evento"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Evento",
  metaInfo: {
    title: "Evento - Taqui",
  },
  data() {
    return {
      loading: true,
      id_evento: null,
      evento: [],
    
    };
  },
  props: {
    perfil: String,
  },
  created: function () {
    this.get_evento();
  },
  methods: {
    get_evento: function () {
      this.id_evento = this.$route.params.id_evento;
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      this.page = this.$route.params.page;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        Token: token_session,
      };
      let config = {
        headers: headers,
      };
      axios
        .get(this.$base_url + "eventos/evento/" + this.id_evento, config, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.status) {
            this.evento = response.data.data;
          }
        })
        .catch()
        .finally();
    },
  },
};
</script>